<template>
	<Toolbar @clickRight="$router.push('/search')">
		<p>{{ $t('account.myAccount') }}</p>
	</Toolbar>
	<div v-if="customer" class="p-5">
		<!-- Member info -->
		<p v-if="customerName.length < 18" class="mb-3 text-xl font-bold">{{ $t('account.welcomeOnboard!') }}{{ customerName }}</p>
		<p v-else class="mb-3 text-sm font-bold">{{ $t('account.welcomeOnboard!') }}{{ customerName }}</p>
		<!-- Member Points -->
		<div class="mb-5 flex justify-around rounded-xl bg-light-blue py-1">
			<div class="flex flex-col items-center py-2 text-gray-500">
				<p class="text-3xl text-primary">{{ customer.pointBalance }}P</p>
				<p class="py-1 text-xs">{{ $t('account.currentPoints') }}</p>
			</div>
			<div class="flex flex-col items-center py-2 text-primary">
				<Icon class="h-9 w-9" icon="giftCard" />
				<p class="py-1 text-xs text-gray-500">{{ currentCustomerLevel?.name[currentLang] }}</p>
			</div>
		</div>
		<!-- Useful Tools -->
		<!-- <p class="mb-3 text-xl font-bold">常用工具</p> -->
		<div class="grid grid-cols-8 rounded-xl bg-light-blue p-2 text-center">
			<div v-for="link in links" :key="link.value" class="col-span-2 flex flex-col items-center py-2 text-gray-500" @click="$router.push(link.value)" :disabled="isLoading">
				<Icon class="h-8 w-8" :icon="link.icon" />
				<p class="py-1 text-xs">{{ link.text }}</p>
			</div>
		</div>
		<!-- logout -->
		<div class="my-5">
			<Button @click="signOut">
				<Icon class="h-5 w-5" icon="rightFromBracket" />
				{{ $t('account.logout') }}
			</Button>
		</div>
	</div>
</template>

<script>
import { storeToRefs } from 'pinia'
import { ref, computed, nextTick, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { useSharedStore } from '@/store/shared'
import { useCustomerStore } from '@/store/customer'

export default {
	setup() {
		const router = useRouter()
		const store = useStore()
		const { config, currentLang } = storeToRefs(useSharedStore())
		const { customerSignOut } = useCustomerStore()
		const { currentCustomer, currentCustomerLevel } = storeToRefs(useCustomerStore())
		const customer = currentCustomer
		const customerName = computed(() => {
			if (customer.value.firstName && customer.value.lastName) return `${customer.value.firstName} ${customer.value.lastName}`
			if (customer.value.firstName) return `${customer.value.firstName}`
			if (customer.value.lastName) return `${customer.value.lastName}`
			if (customer.value.emailAddress) return `${customer.value.emailAddress}`
			if (customer.value.phoneNumber) return `${customer.value.phoneNumber}`
			return ``
		})
		const { t } = useI18n({ useScope: 'global' })
		const links = ref([
			{ value: '/account/profile', text: t('account.personalInfo'), icon: 'user' },
			{ value: '/account/invite', text: t('account.inviteFriends'), icon: 'link' },
			{ value: '/account/orders', text: t('account.myOrder'), icon: 'receipt' },
			{ value: '/account/addresses', text: t('account.myAddress'), icon: 'locationDot' },
			{ value: '/account/wishlist', text: t('account.wishList'), icon: 'heart' },
			{ value: '/account/chat', text: t('account.contactUs'), icon: 'headset' },
			{ value: '/account/help', text: t('account.helpCenter'), icon: 'circleQuestion' },
			{ value: '/account/setting', text: t('account.settings'), icon: 'gear' },
		])
		const error = ref(null)
		const isLoading = computed(() => store.state.loadingState)
		onMounted(async () => {
			await scrollToTop()
		})
		async function scrollToTop() {
			const element = document.getElementById('baseLayout')
			nextTick(() => {
				element.scrollIntoView({ behavior: 'auto', block: 'start' })
			})
		}

		async function signOut() {
			try {
				store.commit('setLoadingState', { loadingState: true })
				error.value = null
				await customerSignOut()
				router.push('/launch')
				store.commit('setLoadingState', { loadingState: false })
			} catch (e) {
				console.error(e)
				error.value = e
				store.commit('setLoadingState', { loadingState: false })
				setTimeout(() => {
					error.value = null
				}, 10000)
			}
		}
		return {
			currentLang,
			currentCustomerLevel,
			config,
			customer,
			customerName,
			links,
			error,
			isLoading,
			signOut,
		}
	},
}
</script>
